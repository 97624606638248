import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  public getCurrentUserId() {
    const user = this.afAuth.auth.currentUser;
    return user.uid;
  }

  public getCurrentUser() {
    const user = this.afAuth.auth.currentUser;
    return this.getUser(user.uid);
  }

  public getUser(uid) {
    return this.afs
      .collection('Users')
      .doc(uid)
      .valueChanges();
  }

  /**
   * Update the Profile data of currently logged in user
   * @param data
   */
  public updateProfile(data) {
    console.log(data);
    const user = this.afAuth.auth.currentUser;
    return this.afs
      .collection('Users')
      .doc(user.uid)
      .set(data, { merge: true });
  }

  /**
   * Change password of currently logged in user
   * @param password
   */
  public updatePassword(password) {
    const user = this.afAuth.auth.currentUser;
    return user.updatePassword(password);
  }

  /**
   * Add a file to the Project
   * @param projectId string
   */
  public updateAvatar = file => {
    const user = this.afAuth.auth.currentUser;
    const filePath = `avatars/${user.uid}-${file.lastModified}-${file.name}`;
    const task = this.storage.upload(filePath, file);
    console.log(file);
    return task.snapshotChanges().pipe(
      finalize(async () => {
        const url = await this.storage
          .ref(filePath)
          .getDownloadURL()
          .toPromise();
        return this.afs
          .collection('Users')
          .doc(user.uid)
          .set(
            {
              avatar: url,
              lastUpdatedAt: new Date()
            },
            { merge: true }
          );
      })
    );
  }
}
