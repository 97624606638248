import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { auth } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) { }

  /**
   * Function will authenticate user with email and password
   * @param {string} email
   * @param {string} password
   */
  public signIn(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Use the Google Auth Provider
   */
  public signInWithGoogle = () => {
    const googleProvider = new auth.GoogleAuthProvider();
    return this.afAuth.auth.signInWithPopup(googleProvider);
  }

  /**
  * Use the Google Auth Provider
  */
  public signInWithFacebook = () => {
    const facebookProvider = new auth.FacebookAuthProvider();
    return this.afAuth.auth.signInWithPopup(facebookProvider);
  }


  /**
   * Creates a new user email, full name and password
   * @param {string} email
   * @param {string} password
   * @param {string} fullName
   */
  public signUp(email: string, password: string, fullName: string) {
    console.log(email, password, fullName);
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
  }


  /**
   * Function sends an email to provided mailId with a link to reset password
   * @param {string} email
   */
  public resetPassword(email: string) {
    console.log(email);
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }
}
