// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDn80JK19vRebpNVQhJ_d85-rxKy3efqa0",
    authDomain: "cover-my-court-date.firebaseapp.com",
    databaseURL: "https://cover-my-court-date.firebaseio.com",
    projectId: "cover-my-court-date",
    storageBucket: "cover-my-court-date.appspot.com",
    messagingSenderId: "742652378762",
    appId: "1:742652378762:web:a5f1b09e6bba151a1f3773",
    measurementId: "G-DWBDE9KYGJ"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
